/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  $.fn.extend({
    toggleClass: function(c) {
        if($(this).hasClass(c)) {
            $(this).removeClass(c);
        }

        else {
            $(this).addClass(c);
        }
    },
  });
})(jQuery);



(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $.viewportWidth = function() {
            return window.innerWidth;
        };

        window.breakpoints = (function() {
            var breakpoints = JSON.parse($(".js-breakpoints-data").text());

            Object.keys(breakpoints).forEach(function(key) {
                // these are defined in ems, so multiply to get
                // the pixel value
                breakpoints[key] = parseInt(breakpoints[key].min_width) * 16;
            });

            return breakpoints;

        })();

        console.log("Registered the following breakpoints:");

        Object.keys(window.breakpoints).forEach(function(key) {
            console.log("\t" + key + ": " + window.breakpoints[key]);
        });

        $(".js-matchheight").matchHeight();
        $(".js-dropdown-height").matchHeight();

        $("label").each(function() {
            var content = $(this).text();

            if(/^\s+$/.test(content)) {
                $(this).remove();
            }
        });

        (function() {
            if(0 !== $(".js-grid").length) $('html').css({ 'overflow-y': 'scroll' })
	    
            $(".js-grid img").on('load', function() {
                $(".js-grid").each(function() {
                    var $grid = $(this);
                    var $nav = $(".horizontal-nav--filter");
                    var fixGutters = function() { // fix issue with scrollbar in some browsers
                        setTimeout(function() {
                            $grid.isotope('layout');
                        }, 500);
                    };
                    $grid.isotope({
                        itemSelector: '.js-grid-item',
                        masonry: {
                            columnWidth: '.js-grid-sizer',
                        }
                    });
                    fixGutters();
                    $nav.find(".horizontal-nav__link").each(function() {
                        $(this).click(function() {
                            var type = $(this).data('type');
                            $nav.find(".horizontal-nav__link").removeClass("horizontal-nav__link--active");
                            $(this).addClass("horizontal-nav__link--active");
                            $grid.isotope({
                                filter: function() {
                                    return 0 === type || $(this).data('types').includes(type);
                                },
                            });
                            fixGutters();
                        });
                    });
                });
            });
        })();

        (function() {
            var active = false;
            $(".header__bars-button").click(function() {
                $(".header__mobile-menu").css({
                    height: active ? 0 : $(".header__mobile-menu__inner").outerHeight(),
                });
                active = !active;
            });
        })();

      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'page_template_template_home': {
      init: function() {
        var timeout;
        var $slider = $(".home-slider");
        var $dots = $(".home-slider__dots__dot");
        var adjustHeight = function() {
            var windowHeight = $(window).height();
            var slideHeight = $('.home-slider__item').height();
            var bodyHeight = $("body").height();

            var difference = windowHeight - bodyHeight;
            var newSlideHeight = slideHeight + difference;

            if(newSlideHeight > 860) {
                newSlideHeight = 860;
            }

            if(newSlideHeight < 200) {
                newSlideHeight = 200;
            }

            $(".home-slider__item").css({
                height: newSlideHeight,
            });
        };

        $(".home-slider__item").click(function() {
            $slider.cycle('next');
        });

        var once = false;

        var timeout;

        var setupTimeout = function() {
            timeout = setTimeout(function() {
                $slider.cycle('next');
            }, 6000);
        };

        $slider.on('cycle-update-view', function() {
            if(once) return;

            $(window).resize(adjustHeight);

            adjustHeight();

            $dots.each(function() {
                $(this).click(function() {
                    $slider.cycle('goto', $(this).data('index'));
                });
            });

            $slider.on('cycle-before', function(event, options, outgoing, incoming, forwardFlag) {
                var index = $(incoming).data('index');
                var currentClass = 'home-slider__dots__dot--current';

                $dots.each(function() {
                    if(index === $(this).data('index') && !$(this).hasClass(currentClass)) {
                        $(this).addClass(currentClass);
                    }
                    else {
                        $(this).removeClass(currentClass);
                    }
                });

                clearTimeout(timeout);

                setupTimeout();
            });

            once = true;
        });

        $slider.cycle({
            slides: ".home-slider__item",
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'index': {
        init: function() {
        },
    },
    'single_project': {
        init: function() {
            $(".js-gallery-item").each(function() {
                var originalUrl = $(this).attr('href');
                var largeImage = $(this).data('large');

                $(this).click(function(ev) {
                    if($(window).width() >= window.breakpoints.sm) {
                        ev.preventDefault();
                        $.featherlight(
                            $('<div class="bg-white p3">'
                                + '<a target="_blank" class="block" href="' + originalUrl + '">'
                                    + largeImage
                                + '</a>'
                            + '</div>')
                        );
                    }
                });
            });
        },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
